<template>
  <router-view />
</template>

<script>
import CountryService from './service/CountryService';
import LanguageService from './service/LanguageService';
import AccessService from './service/AccessService';

export default {
  name: 'App',
  created() {
    CountryService.getAll().then (
      response => {
        this.$store.commit('common/countries', Object.fromEntries(response.data.map(item => [item.code, item.name])))
      }
    );
    LanguageService.getAll().then (
      response => {
        this.$store.commit('common/languages', Object.fromEntries(response.data.map(item => [item.code, item.name])))
      }
    );
    AccessService.getAll().then (
      response => {
        this.$store.commit('common/accesses', Object.fromEntries(response.data.map(item => [item.code, item.name])))
      }
    );
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>
