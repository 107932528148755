import { http } from '../plugin/http2-common';

class AccessService {

  getAll() {
    return http.get('access');
  }
  
}

export default new AccessService();