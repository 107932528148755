import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from './auth.module'
import azure from "./azure/store"
import { common } from './common.module'
import email from './email/store'
import lensSlider from './lensSlider/store'
import other from './other/store'
import profile from './profile/store'
import questionnaire from './questionnaire/store'
import { survey } from './survey.module'

Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false
}

const mutations = {
  toggleSidebarDesktop (state) {
    const sidebarOpened = [true, "responsive"].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile (state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set (state, [variable, value]) {
    state[variable] = value
  }
}

export default new Vuex.Store({
  state,
  mutations,
  modules: {
    auth,
    questionnaire,
    other,
    survey,
    email,
    common,
    lensSlider,
    profile,
    azure
  }
})