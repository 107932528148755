/* eslint-disable no-prototype-builtins */
import $ from "jquery";
import _ from "lodash";

/** `Object#toString` result references. */
// https://github.com/lodash/lodash/blob/master/.internal/baseClone.js
// const argsTag = '[object Arguments]';
const arrayTag = "[object Array]";
const boolTag = "[object Boolean]";
const dateTag = "[object Date]";
const errorTag = "[object Error]";
const mapTag = "[object Map]";
const numberTag = "[object Number]";
const objectTag = "[object Object]";
const regexpTag = "[object RegExp]";
const setTag = "[object Set]";
// const stringTag = '[object String]';
// const symbolTag = '[object Symbol]';
//const weakMapTag = '[object WeakMap]';
const functionTag = "[object Function]";
const isBoolean = (data) =>
  Object.prototype.toString.call(data) === boolTag ||
  data === "true" ||
  data === "false";
const isNumber = (data) => Object.prototype.toString.call(data) === numberTag;
const isDate = (data) => Object.prototype.toString.call(data) === dateTag;
const isArray = (data) => Object.prototype.toString.call(data) === arrayTag;
const isFuntion = (data) =>
  Object.prototype.toString.call(data) === functionTag;
const isError = (data) => Object.prototype.toString.call(data) === errorTag;
const isString = (data) => typeof data === "string" || data instanceof String;
const isRegexp = (data) => Object.prototype.toString.call(data) === regexpTag;
const isCollections = (data) =>
  Object.prototype.toString.call(data) === mapTag ||
  Object.prototype.toString.call(data) === setTag;
const isObject = (data) => Object.prototype.toString.call(data) === objectTag;

const getDataType = (data) => {
  const rawType = Object.prototype.toString
    .call(data)
    .toString()
    .split(" ")[1];
  return rawType.substring(0, rawType.length - 1);
};

/**
 * Check function is realy empty
 * @param {*} data
 * @returns
 */
const isPureFunctionEmpty = (data) => {
  if (!isFuntion(data)) {
    return false;
  }
  // data.toString() return [object Function][funtion content]
  const rawFuntionContent = data.toString().split(functionTag)[0];
  // es6 syntax
  if (rawFuntionContent.includes("=>")) {
    const funtionEs6Content = rawFuntionContent.split("=>")[0];
    if (funtionEs6Content.includes("{") && funtionEs6Content.includes("}")) {
      // exclude {} -2
      return funtionEs6Content.length - 2 === 0;
    }
    return funtionEs6Content.length === 0;
  }
  // es5 syntax
  const funtionEs5Content = rawFuntionContent.split("function")[0];
  if (funtionEs5Content.includes("{") && funtionEs5Content.includes("}")) {
    return funtionEs5Content.length - 2 === 0;
  }

  return funtionEs5Content.length === 0;
};

/**
 * Check is empty variable
 * @param {*} variable variable type
 * @returns
 */
const isEmpty = (variable) => {
  if (isNull(variable)) {
    return true;
  }

  if (isCollections(variable)) {
    return variable.size === 0;
  }
  if (isError(variable)) {
    const rawErrorContent = variable.toString().split(errorTag)[0];
    return rawErrorContent.toString().split("Error:").length === 0;
  }
  if (isRegexp(variable)) {
    return variable.toString().length === 0;
  }
  if (isFuntion(variable)) {
    return isPureFunctionEmpty(variable);
  }
  if (isObject(variable)) {
    return Object.keys(variable).length === 0;
  }

  if (isArray(variable) || isString(variable)) {
    return variable.length === 0;
  }

  if (
    getDataType(variable) === "Number" ||
    getDataType(variable) === "BigInt" ||
    getDataType(variable) === "Boolean"
  ) {
    return false;
  }

  return _.isEmpty(variable);
};

const isNotNull = (data) => {
  return data !== undefined && data !== null;
};

const isNull = (data) => !isNotNull(data);

const isNotEmpty = (data) => !isEmpty(data);

/*
 * Will force update properties in all level of object else add new
 */
let addPropertyData = (object, { propertyName, value }) => {
  if (isArray(object)) {
    object = object.map((item) =>
      addPropertyData(item, { propertyName, value })
    );
  } else if (
    isNumber(object) ||
    isBoolean(object) ||
    isString(object) ||
    isDate(object)
  ) {
    let objectDefined = { data: object };
    objectDefined[propertyName] = value;
    return objectDefined;
  } else if (isObject(object)) {
    for (const propertyKey in object) {
      let propertyValue = object[propertyKey];
      if (propertyKey === propertyName) {
        object[propertyKey] = value;
      } else {
        object[propertyKey] = addPropertyData(propertyValue, {
          propertyName,
          value,
        });
      }
    }
  }

  return object;
};

/*
 * Will update first properties find in all level of object
 */
let changePropertyData = (object, { propertyName, value }) => {
  if (isArray(object)) {
    object = object.map((item) =>
      changePropertyData(item, { propertyName, value })
    );
  } else if (isObject(object)) {
    for (const propertyKey in object) {
      let propertyValue = object[propertyKey];
      if (propertyKey === propertyName) {
        object[propertyKey] = value;
      } else if (isObject(propertyValue)) {
        object[propertyKey] = changePropertyData(propertyValue, {
          propertyName,
          value,
        });
      } else if (isArray(propertyValue)) {
        object[propertyKey] = propertyValue.map((item) =>
          changePropertyData(item, { propertyName, value })
        );
      }
    }
  }

  return object;
};

/*
 * Will force update properties in all level of object else add new arr data
 */
let addPropertyDataArr = (
  object,
  properties = [{ propertyName: "", value: "" }]
) => {
  if (isArray(object)) {
    object = object.map((item) => addPropertyDataArr(item, properties));
  } else if (
    isNumber(object) ||
    isBoolean(object) ||
    isString(object) ||
    isDate(object)
  ) {
    let objectDefined = { data: object };
    properties.map((property) => {
      objectDefined[property.propertyName] = property.value;
    });

    return objectDefined;
  } else if (isObject(object)) {
    for (const propertyKey in object) {
      const propertyValue = object[propertyKey];
      // const newProperties = [];
      // properties.map((property) => {
      //   if (propertyKey === property.propertyName) {
      //     object[propertyKey] = property.value;
      //   } else {
      //     newProperties.push(property);
      //   }
      // });
      // if (newProperties.length > 0)
      object[propertyKey] = addPropertyDataArr(propertyValue, properties);
    }
  }

  return object;
};

const copyObjectProperty = (object, fromProperty, toProperty) => {
  if (isArray(object)) {
    object = object.map((item) =>
      copyObjectProperty(item, fromProperty, toProperty)
    );
  } else if (isObject(object)) {
    if (
      object.hasOwnProperty(fromProperty) &&
      object.hasOwnProperty(toProperty)
    ) {
      object[toProperty] = object[fromProperty];
    } else {
      for (const propertyKey in object) {
        const propertyValue = object[propertyKey];
        object[propertyKey] = copyObjectProperty(
          propertyValue,
          fromProperty,
          toProperty
        );
      }
    }
  }
  return object;
};

/**
 * Remove propertYName from all level of object
 * @param {*} object
 * @param {*} propertyName
 */
const removeObjectProperty = (object, propertyName) => {
  const checkObjectProperty = (object, propertyName) => {
    if (isNotNull(object) && isObject(object)) {
      for (const key in object) {
        // eslint-disable-next-line no-prototype-builtins
        if (key.toLocaleLowerCase() === propertyName.toLocaleLowerCase()) {
          delete object[propertyName];
          return;
        }
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          checkObjectProperty(object[key], propertyName);
        }
      }
    }
  };
  checkObjectProperty(object, propertyName);
};

/**
 * Random data from number, alphabet or special character
 */
const randomData = (max = 40, type = 0) => {
  max = max <= 0 ? 0 : max;

  const NUMBER = "0123456789";
  const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
  const SPECIAL_CHARS = "-~!@#$%^&*_+|<>:.,?";
  const LETTERS = `${NUMBER}${ALPHABET}${SPECIAL_CHARS}`;

  const getRandomByType = (type) => {
    const getRndInteger = (min, max) =>
      Math.floor(Math.random() * (max - min)) + min;
    let str = "";
    switch (type) {
      case 1:
        str = `${NUMBER}${ALPHABET}`[
          getRndInteger(0, `${NUMBER}${ALPHABET}`.length - 1)
        ];
        break;

      case 2:
        str = `${NUMBER}`[getRndInteger(0, NUMBER.length - 1)];
        break;

      case 3:
        str = `${ALPHABET}`[getRndInteger(0, ALPHABET.length - 1)];
        break;

      default:
        str = LETTERS[getRndInteger(0, LETTERS.length - 1)];
        break;
    }

    return str;
  };

  let name = "";
  for (let ind = 0; ind <= max; ind++) name += getRandomByType(type);

  return name;
};

/**
  update value if originData from formatData,
  with condition that originData and formatData 
  has the same property but diffrent data
 * @param {*} originData 
 * @param {*} formatData 
 * @param {*} replaceField 
 * @returns 
 */
const reformatData = (originData, formatData, replaceField) => {
  if (
    isArray(formatData) &&
    isArray(originData) &&
    originData.length === formatData.length
  ) {
    for (let ind = 0; ind < originData.length; ind++) {
      originData[ind] = reformatData(
        originData[ind],
        formatData[ind],
        replaceField
      );
    }
  } else if (
    isNumber(originData) ||
    isBoolean(originData) ||
    isString(originData) ||
    isDate(originData)
  ) {
    return isObject(formatData)
      ? isNotNull(formatData[replaceField])
        ? formatData[replaceField]
        : originData
      : formatData;
  } else if (isObject(formatData) && isObject(originData)) {
    for (const propertyKey in originData) {
      // eslint-disable-next-line no-prototype-builtins
      formatData.hasOwnProperty(propertyKey) &&
        (originData[propertyKey] = reformatData(
          originData[propertyKey],
          formatData[propertyKey],
          replaceField
        ));
    }
  }

  return originData;
};

/**
 * Check String is json
 * @param {*} str string value
 * @returns
 */
const isJson = (str) => {
  if (!isString(str) || isEmpty(str)) return false;
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const equalsIgnoreCase = (value1, value2) => {
  if (!value1 && !value2) {
    return true;
  }

  if (!value1 || !value2) {
    return false;
  }
  return ("" + value1).toLowerCase().indexOf(value2.toLowerCase()) != -1;
};

const fixHeaderTable = (currentId, anchorId, isEdit = false, top = "165px") => {
  let currentOfset = $("#" + currentId).offset();
  if (currentOfset === undefined)
    return
  let tableOffset = currentOfset.top;
  const anchorHeight = $("#" + anchorId).height() || 0;
  let parrent = $("#" + currentId).parent();

  const createCloneHeaderTable = () => {
    let table = $("<table id='header-fixed'></table>");
    parrent.append(table);
    let $header = $("#" + currentId + "> thead").clone();
    let $fixedHeader = $("#header-fixed").append($header);
    $fixedHeader.addClass(
      $("#" + currentId).attr("class") +
        " table-hover table-sm b-table-caption-top"
    );
    $("#header-fixed").css("position", "fixed");
    $("#header-fixed").css("display", "none");
    $("#header-fixed").css("top", top);
    $(".topnav").css("padding-bottom", "16px");
    $("#header-fixed").css("z-index", 1);

    return $fixedHeader;
  };

  let $fixedHeader = null;
  if (!$("#header-fixed").length) {
    $fixedHeader = createCloneHeaderTable();
  } else {
    $fixedHeader = $("#header-fixed");
  }

  let currentTDWidth = 0;
  const storageThWidth = [];
  $("#" + currentId + " th").each(function() {
    currentTDWidth += $(this)[0].clientWidth;
    storageThWidth.push($(this)[0].clientWidth);
  });

  $("#header-fixed").css("width", currentTDWidth);

  // console.log("storageThWidth", storageThWidth);
  // add event lisener
  $(window).bind("scroll", function() {
    let offset = $(this).scrollTop();
    if (offset >= tableOffset - anchorHeight) {
      let ind = 0;
      $("#header-fixed th").each(function() {
        $(this)[0].style.width = storageThWidth[ind++] + "px";

        if (isEdit) {
          !$(this).hasClass("edit-th-content") &&
            $(this).addClass("edit-th-content");
          $(this).hasClass("normal-th-content") &&
            $(this).removeClass("normal-th-content");
        } else {
          $(this).hasClass("edit-th-content") &&
            $(this).removeClass("edit-th-content");
          !$(this).hasClass("normal-th-content") &&
            $(this).addClass("normal-th-content");
        }
      });
      $fixedHeader.show();
    } else if (offset < tableOffset) {
      $fixedHeader.hide();
    }
  });
};

const isFloatNumber = (num) => /^-?\d+\.\d+$/.test(num + "");

const isRealNumber = (num) => /^-?\d+(?:\.\d+)?$/.test(num + "");

const range = (start, end, incre) => {
  let arr = [];
  const fixedStart = isFloatNumber(start) ? (start + "").split(".")[1].length : 0;
  const fixedEnd = isFloatNumber(end) ? (end + "").split(".")[1].length : 0;
  const fixedIncre = isFloatNumber(incre) ? (incre + "").split(".")[1].length : 0;
  for (let ind = start; ind <= end; ind += incre) {
    +ind <= +end &&
    arr.push(+ind.toFixed(Math.max(fixedStart, fixedEnd, fixedIncre)));
  }
  return arr;
};

const rangeEditNumber = (start, end, incre) => {
  let arr = [];
  const fixedStart = isFloatNumber(start) ? (start + "").split(".")[1].length : 0;
  const fixedEnd = isFloatNumber(end) ? (end + "").split(".")[1].length : 0;
  const fixedIncre = isFloatNumber(incre) ? (incre + "").split(".")[1].length : 0;
  for (let ind = start; ind <= end; ind += incre) {
    +ind <= +end &&
    arr.push(+ind.toFixed(Math.max(fixedStart, fixedEnd, fixedIncre)));
  }
  let arrNew = [];
  for (let index = 0; index < arr.length; index++) {
    let convert = parseFloat(arr[index]).toFixed(2)
    arrNew.push(convert);
  }
  return arrNew;
};

const OK_CONFIRM = "ok";
const NOT_OK_CONFIRM = "not ok";

const MODAL_CANCEL = {
  title: "All changes will be removed.\nSelect “confirm” to continue.",
  buttonOk: "Confirm",
  buttonNotOK: "Cancel",
};

const MODAL_SAVE = {
  title: "All changes will be saved.\nSelect “confirm” to continue.",
  buttonOk: "Confirm",
  buttonNotOK: "Cancel",
};

export {
  MODAL_CANCEL,
  MODAL_SAVE, NOT_OK_CONFIRM, OK_CONFIRM, addPropertyData, addPropertyDataArr, changePropertyData, copyObjectProperty, equalsIgnoreCase,
  fixHeaderTable, isArray, isBoolean,
  isEmpty, isFloatNumber, isJson, isNotEmpty, isNotNull,
  isNull, isObject, isRealNumber, randomData, range, rangeEditNumber, reformatData, removeObjectProperty
};

