export default {
    setEditable(state, payload) {
        state.editable = payload
    },
    setMessage(state, payload) {
        state.message = payload
    },
    setShowError(state, payload) {
        state.showError = payload
    },
    setProgress(state, payload) {
        state.progress = payload
    },
    setSingleVisionCategory(state, payload) {
        state.singleVisionCategory = payload
    },
    setSingleVisionCategoryImageWithWireframe(state, payload) {
        state.singleVisionCategory.products[payload.index].lensSlider.imageWithWireframe = payload.image
    },
    setSingleVisionCategoryImageWithoutWireframe(state, payload) {
        state.singleVisionCategory.products[payload.index].lensSlider.imageWithoutWireframe = payload.image
    },
    // single vision
    setSingleVisionCategoryImageWithWireframeWithoutBlur(state, payload) {
        state.singleVisionCategory.products[payload.index].lensSlider.imageWithWireframeWithoutBlur = payload.image
    },
    setSingleVisionCategoryImageWithoutWireframeWithoutBlur(state, payload) {
        state.singleVisionCategory.products[payload.index].lensSlider.imageWithoutWireframeWithoutBlur = payload.image
    },
    setProgressiveLensCategory(state, payload) {
        state.progressiveLensCategory = payload
    },
    setProgressiveLensCategoryImageWithWireframe(state, payload) {
        state.progressiveLensCategory.products[payload.index].lensSlider.imageWithWireframe = payload.image
    },
    setProgressiveLensCategoryImageWithoutWireframe(state, payload) {
        state.progressiveLensCategory.products[payload.index].lensSlider.imageWithoutWireframe = payload.image
    },
    // conventional
    setConvetionalCategory(state, payload) {
        state.conventionalCategory = payload
    },
    setConvetionalCategoryImageWithoutWireframe(state, payload) {
        state.conventionalCategory.products[payload.index].lensSlider.imageWithoutWireframe = payload.image
    },
    setConvetionalCategoryImageWithWireframe(state, payload) {
        state.conventionalCategory.products[payload.index].lensSlider.imageWithWireframe = payload.image
    },
    setVoilaCategory(state, payload) {
        state.voilaCategory = payload
    },
    setSeriesCategory(state, payload) {
        state.seriesCategory = payload
    },
    setVoilaCategoryImageWithWireframe(state, payload) {
        state.voilaCategory.products[payload.index].lensSlider.imageWithWireframe = payload.image
    },
    setVoilaCategoryImageWithoutWireframe(state, payload) {
        state.voilaCategory.products[payload.index].lensSlider.imageWithoutWireframe = payload.image
    },
    setSeriesCategoryImageWithWireframe(state, payload) {
        state.seriesCategory.products[payload.index].lensSlider.imageWithWireframe = payload.image
    },
    setSeriesCategoryImageWithoutWireframe(state, payload) {
        state.seriesCategory.products[payload.index].lensSlider.imageWithoutWireframe = payload.image
    },
}
