import { http } from '../plugin/http2-common';

class CountryService {

  getAll() {
    return http.get('language');
  }
  
}

export default new CountryService();