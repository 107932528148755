/* eslint-disable no-console */
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import state from './state';

const lensSlider = {
    state,
    getters,
    mutations,
    actions
}

export default lensSlider;