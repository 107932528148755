/* eslint-disable no-console */

import _ from "lodash";
import {
  addPropertyData,
  addPropertyDataArr,
  copyObjectProperty,
} from "../../common/CommonUtils";
const QUESTIONNAIRE_TYPE = "questionnaire";
const PRESCRIPTION_TYPE = "prescription";

const questionnaire = {
  namespaced: true,
  state: () => ({
    prescription: {
      editStatus: false,
      prescriptionData: [],
      prescriptionOriginData: [],
    },
    questionnaire: {
      editStatus: false,
      questionnaireData: [],
      questionnaireOriginData: [],
    },
  }),
  mutations: {
    changeEditStatus(state, payload = { type: "", data: false }) {
      payload.type === PRESCRIPTION_TYPE &&
        (state.prescription.editStatus = payload.data);
      payload.type === QUESTIONNAIRE_TYPE &&
        (state.questionnaire.editStatus = payload.data);
    },
    changeQuestionnaireData(state, payload = { data: [] }) {
      state.questionnaire.questionnaireData = _.cloneDeep(payload.data);
    },
    changePrescriptionData(state, payload = { data: [] }) {
      state.prescription.prescriptionData = _.cloneDeep(payload.data);
    },

    changeQuestionnaireOriginData(state, payload = { data: [] }) {
      state.questionnaire.questionnaireOriginData = _.cloneDeep(payload.data);
    },
    changePrescriptionOriginData(state, payload = { data: [] }) {
      state.prescription.prescriptionOriginData = _.cloneDeep(payload.data);
    },
  },
  getters: {},
  actions: {
    initialQuestionnaireData(state, payload = {}) {
      const addTempProperty = addPropertyDataArr(_.cloneDeep(payload), [
        { propertyName: "isEdit", value: false },
        { propertyName: "editData", value: "" },
      ]);

      const editData = copyObjectProperty(addTempProperty, "data", "editData");
      state.commit("changeQuestionnaireData", editData);
      state.commit("changeQuestionnaireOriginData", payload);
    },

    initialPrescriptionData(state, payload = {}) {
      state.commit(
        "changePrescriptionData",
        addPropertyData(_.cloneDeep(payload), {
          propertyName: "isEdit",
          value: false,
        })
      );
      state.commit("changePrescriptionOriginData", payload);
    },
  },
};

export default questionnaire;
