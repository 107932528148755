import { http } from '../plugin/http2-common';

class LanguageService {

  getAll() {
    return http.get('country');
  }
  
}

export default new LanguageService();