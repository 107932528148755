export default {
    updateEditable( {commit}, payload ) {
        commit('setEditable', payload)
    },
    updateMessage( {commit}, payload ) {
        commit('setMessage', payload)
    },
    updateShowError( {commit}, payload ) {
        commit('setShowError', payload)
    },
    updateProgress( {commit}, payload ) {
        commit('setProgress', payload)
    },
    updateSingleVisionCategory( {commit}, payload ) {
        commit('setSingleVisionCategory', payload)
    },
    updateSingleVisionCategoryImageWithWireframe( {commit}, payload ) {
        commit('setSingleVisionCategoryImageWithWireframe', payload)
    },
    updateSingleVisionCategoryImageWithoutWireframe( {commit}, payload ) {
        commit('setSingleVisionCategoryImageWithoutWireframe', payload)
    },
    // singleVision
    updateSingleVisionCategoryImageWithWireframeWithoutBlur( {commit}, payload ) {
        commit('setSingleVisionCategoryImageWithWireframeWithoutBlur', payload)
    },
    updateSingleVisionCategoryImageWithoutWireframeWithoutBlur( {commit}, payload ) {
        commit('setSingleVisionCategoryImageWithoutWireframeWithoutBlur', payload)
    },
    updateProgressiveLensCategory( {commit}, payload ) {
        commit('setProgressiveLensCategory', payload)
    },
    updateProgressiveLensCategoryImageWithWireframe( {commit}, payload ) {
        commit('setProgressiveLensCategoryImageWithWireframe', payload)
    },
    updateProgressiveLensCategoryImageWithoutWireframe( {commit}, payload ) {
        commit('setProgressiveLensCategoryImageWithoutWireframe', payload)
    },
    // conventional
    updateConventionalCategory( {commit}, payload ) {
        commit('setConvetionalCategory', payload)
    },
    updateConvetionalCategoryImageWithoutWireframe( {commit}, payload ) {
        commit('setConvetionalCategoryImageWithoutWireframe', payload)
    },
    // with wireframe
    updateConvetionalCategoryImageWithWireframe({commit}, payload) {
        commit('setConvetionalCategoryImageWithWireframe', payload)
    },
    updateVoilaCategory( {commit}, payload ) {
        commit('setVoilaCategory', payload)
    },
    updateSeriesCategory( {commit}, payload ) {
        commit('setSeriesCategory', payload)
    },
    updateVoilaCategoryImageWithWireframe( {commit}, payload ) {
        commit('setVoilaCategoryImageWithWireframe', payload)
    },
    updateVoilaCategoryImageWithoutWireframe( {commit}, payload ) {
        commit('setVoilaCategoryImageWithoutWireframe', payload)
    },
    updateSeriesCategoryImageWithWireframe( {commit}, payload ) {
        commit('setSeriesCategoryImageWithWireframe', payload)
    },
    updateSeriesCategoryImageWithoutWireframe( {commit}, payload ) {
        commit('setSeriesCategoryImageWithoutWireframe', payload)
    },
}
