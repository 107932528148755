import AuthService from "../service/auth.service";
import LocalStoreService from "../service/LocalStoreService";

const user = LocalStoreService.getUser();
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null };

export const auth = {
  namespaced: true,
  state: initialState,
  getters: {
    canAccessLenSelector (state) {
      if(!state.user) return false;
      return state.user.isGlobal || state.user.isAccessToLensSelector;
    }
  },
  actions: {
    login({ commit }, user) {
      return AuthService.login(user).then(
        (user) => {
          commit("loginSuccess", user);
          return Promise.resolve(user);
        },
        (error) => {
          commit("loginFailure");
          return Promise.reject(error);
        }
      );
    },
    loginAzure({ commit }, token) {
      return AuthService.loginAzure(token).then(
          (user) => {
            commit("loginSuccess", user);
            return Promise.resolve(user);
          },
          (error) => {
            commit("loginFailure");
            return Promise.reject(error);
          }
      );
    },
    changePassword({ commit }, { oldPassword, newPassword }) {
      return AuthService.changePassword(oldPassword, newPassword).then(
        () => {
          commit("loginchangePasswordSuccess");
          return Promise.resolve(true);
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    },
    logout({ commit }) {
      AuthService.logout();
      commit("logout");
    },
  },
  mutations: {
    loginSuccess(state, user) {
      state.status.loggedIn = true;
      state.user = user;
    },
    loginFailure(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
    loginchangePasswordSuccess(state) {
      if (state.user) state.user = { ...state.user, isFirstLogin: false };
    },
    logout(state) {
      state.status.loggedIn = false;
      state.user = null;
    },
  },
};
