export const ROLE_ADMIN = "ROLE_ADMIN";
export const ROLE_COUNTRY = "ROLE_COUNTRY";
export const ROLE_STANDARD = "ROLE_STANDARD";

export const COUNTRY_ROLE_NAMES = {
  [ROLE_COUNTRY]: "Country",
  [ROLE_STANDARD]: "Standard",
};

export const ROLE_NAMES = {
  [ROLE_ADMIN]: "Global",
  ...COUNTRY_ROLE_NAMES,
};

export const DATA_ACCESS = {
  [1]: "Yes",
  [0]: "No"
};

export const GLOBAL_COUNTRY_CODE = "GLB";
export const GLOBAL_COUNTRY_NAME = "Global";
