export default {
    isEditable: state => state.editable,
    getMessage: state => state.message,
    isShowError: state => state.showError,
    getProgress: state => state.progress,
    getSingleVisionCategory: state => state.singleVisionCategory,
    getProgressiveLensCategory: state => state.progressiveLensCategory,
    getVoilaCategory: state => state.voilaCategory,
    getConventionalCategory: state => state.conventionalCategory,
    getSeriesCategory: state => state.seriesCategory,
}