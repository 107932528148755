import axios from "axios";
import Vue from 'vue';
import { CONTEXT_PATH, notEditModePages } from '../constants/Paths';
import { router } from '../router';
import LocalStoreService from '../service/LocalStoreService';
import SurveyService from "../service/SurveyService";
import store from '../store/store';

export const http = axios.create({
  baseURL: CONTEXT_PATH
});

const authInterceptor = (config) => {
  config.headers['Authorization'] = authHeader();
  return config;
}

function authHeader() {
    let user = LocalStoreService.getUser();
    if (user && user.token) {
      return 'Bearer ' + user.token;
    } else {
      return '';
    }
}

function showServerError(message = 'Server error') {
  Vue.$toast.open({
    message,
    type: 'error',
  })
}

const responseInterceptor = response => response

const errorInterceptor = error => {
  if (!error.response) {
    showServerError();
    return Promise.reject(error);
  }
  const status = error.response.status;
  if (status == 401) {
    store.dispatch('auth/logout')
    router.push('/login')
  } else if (status == 400) {
    const data = error.response && error.response.data ? error.response.data : {}
    if (data && data.message) {
      showServerError(data.message);
    } else if (data instanceof Blob) {
      data.text().then(result => {
        showServerError(JSON.parse(result).message);
      })
    }
  } else if ( status >= 500) {
    console.log("error", {error});
    showServerError(error?.response?.data?.message);
  }
  return Promise.reject(error);
}

const UPDATE_METHODS = ['post', 'put']
const PUBLISHED_SURVEY_API_URL_REGEX = /(\/api\/survey\/publish)|(\/api\/survey\/publishFlag)|(\/api\/upload)/
const publishedSurveyInterceptor = (config) => {
  const { method, url } = config;
  const survey = store.state.survey;
  if (
    UPDATE_METHODS.includes(method) 
    && survey
    && !PUBLISHED_SURVEY_API_URL_REGEX.test(url)
    && !notEditModePages.includes(window.location.pathname)
  ) {
    SurveyService.publishFlag(survey.id, false)
      .then(({ data }) => {
        survey.published = data.published;
        store.commit("survey/save", survey);
      })
      .catch((reason) => {
        console.log(reason)
      })
  }
  return config;
}

http.interceptors.request.use(authInterceptor);
http.interceptors.request.use(publishedSurveyInterceptor);
http.interceptors.response.use(responseInterceptor, errorInterceptor);
