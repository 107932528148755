/* eslint-disable no-console */
import _ from 'lodash';
import { addPropertyData } from '../../common/CommonUtils';

const OPTICIAN_EMAIL_TYPE = "OpticianEmail";
const AFTER_SALES_EMAIL_TYPE = "AfterSalesEmail";

const email = {
    namespaced: true,
    state: () => ({
            opticianEmail: {
                editStatus: false,
                opticianData: [],
                opticianOriginData: []
            },
            afterSalesEmail: {
                editStatus: false,
                afterSalesData: [],
                afterSalesOriginData: []
            }
        }),
    mutations: {
        changeEditStatus(state, payload = { type: '', data: false}) {
            payload.type == OPTICIAN_EMAIL_TYPE && (state.opticianEmail.editStatus = payload.data)
            payload.type == AFTER_SALES_EMAIL_TYPE && (state.afterSalesEmail.editStatus = payload.data)
        },
        changeOpticianEmailData(state, payload = { data: []}){
            state.opticianEmail.opticianData = _.cloneDeep(payload.data)
        },
        changeAfterSalesEmailData(state, payload = { data: []}){
            state.afterSalesEmail.afterSalesData = _.cloneDeep(payload.data)
        },
        changeAfterSalesEmailOriginData(state, payload = { data: []}){
            state.afterSalesEmail.afterSalesOriginData = _.cloneDeep(payload.data)
        },
        changeOpticianEmailOriginData(state, payload = { data: []}){
            state.opticianEmail.opticianOriginData = _.cloneDeep(payload.data)
        }
    },
    getters: {

    },
    actions: {  
        initialOpticianEmailData(state, payload = {}){
            state.commit('changeOpticianEmailData', addPropertyData(_.cloneDeep(payload), { propertyName: 'isEdit', value: false }))
            state.commit('changeOpticianEmailOriginData', payload)
        },
        initialAfterSalesEmailData(state, payload = {}){
            state.commit('changeAfterSalesEmailData', addPropertyData(_.cloneDeep(payload), { propertyName: 'isEdit', value: false }))
            state.commit('changeAfterSalesEmailOriginData', payload)
        },
    }
}

export default email;