const initialState = {
    countries: {},
    languages: {},
    accesses: { ALL: 'All', ECP: 'ECP' },
    theHeaderHeight: 0
};

export const common = {
  namespaced: true,
  state: initialState,
  actions: {
    countries({ commit }) {
      commit('countries');
    },
    languages({ commit }) {
      commit('countries');
    },
    accesses({ commit }) {
      commit('accesses');
    }
  },
  mutations: {
    countries(state, countries) {
      state.countries = countries;
    },
    languages(state, languages) {
      state.languages = languages;
    },
    accesses(state, accesses) {
      state.accesses = accesses;
    }
  }
};