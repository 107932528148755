import _ from "lodash";
import LocalStoreService from "../service/LocalStoreService";

const initialState = {
  id: null,
  country: { code: "", name: "" },
  language: { code: "", name: "" },
  countryAndLanguage: "",
  published: true,
  parentId: null,
  content: {},
  showURL: true,
  surveyFileItem: null,
  isSurveyEditing: false,
  editingScreenSaveFunc: null
};

export const survey = {
  namespaced: true,
  state: { ...initialState },
  actions: {
    save({ commit }) {
      commit("save");
    },
    remove({ commit }) {
      commit("remove");
    },
  },
  mutations: {
    changeSurveyFileItem(state, data) {
      state.surveyFileItem = _.cloneDeep(data);
    },
    save(state, survey) {
      state.id = survey.id;
      state.parentId = survey.parentId;
      state.country = survey.country;
      state.language = survey.language;
      state.published = survey.published;
      state.countryAndLanguage = `${survey.country.name}: ${survey.language.name}`;
      state.content = survey.content;
      state.showURL = survey.showURL;
      LocalStoreService.setSurvey(survey);
    },
    remove(state) {
      state.id = initialState.id;
      state.parentId = initialState.parentId;
      state.country = initialState.country;
      state.language = initialState.language;
      state.countryAndLanguage = initialState.countryAndLanguage;
      state.content = initialState.content;
      state.showURL = initialState.showURL;
      LocalStoreService.removeSurvey();
    },
  },
};
