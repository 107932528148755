// Paths.js
export const CONTEXT_PATH = process.env.VUE_APP_API_CMS_URL || 'http://localhost:8080/'
//export const CONTEXT_PATH = process.env.VUE_APP_API_CMS_URL

export const LOAD_IMAGE_API_PATH = "api/files/";

export const LOAD_LARGE_IMAGE_API_PATH = "api/large-files/";

export const SURVEY_FE_URL = process.env.VUE_APP_SURVEY_FE_URL || 'http://localhost:8080/questionnaire/'

export const DOCUMENT_PATH = process.env.VUE_APP_DOCUMENT_SITE_URL;

// Azure
export const AZURE_CLIENT_ID = process.env.VUE_APP_AZURE_CLIENT_ID;
export const AZURE_AUTHORITY = process.env.VUE_APP_AZURE_AUTHORITY;

export const notEditModePages = [
  "/",
  "/login",
  "/redirect",
  "/survey",
  "/survey/survey-package",
  "/admin",
  "/admin/platform",
  "/admin/users",
  "/admin/privacy",
  "/admin/password",
  "/change-password",
  "/forgot-password"
];
