import { AZURE_AUTHORITY, AZURE_CLIENT_ID } from "@/constants/Paths";

const azure = {
      state: () => ({
        msalConfig: {
          auth: {
            clientId: AZURE_CLIENT_ID,
            authority: AZURE_AUTHORITY,
          },
        },
        accessToken: ""
      }),
      mutations: {
        setAccessToken(state, token) {
          state.accessToken = token;
        }
      }
    };
export default azure;
