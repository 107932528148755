import Vue from "vue";
import Router from "vue-router";
import { MENU_LIST } from "../constants/HeaderMenus";
import { DOCUMENT_PATH, notEditModePages } from "../constants/Paths";
import LocalStoreService from "../service/LocalStoreService";
import store from "../store/store";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Admin
const Admin = () => import("@/views/admin");
const ManagePlatform = () => import("@/views/admin/platform");
const ManageUser = () => import("@/views/admin/user");
const PrivacySetting = () => import("@/views/admin/privacy");

// Survey
const Survey = () => import("@/views/survey");
const SurveyCreation = () => import("@/views/survey/SurveyCreation");
const SurveyFile = () => import("@/views/survey/components/SurveyFile");
const SurveyPackage = () => import("@/views/survey/SurveyPackage");
const SurveyPackageDetail = () =>
  import("@/views/survey/components/SurveyPackageDetail");
const SurveyPackageAdd = () =>
  import("@/views/survey/components/SurveyPackageAdd");
const SurveyPackageEdit = () =>
  import("@/views/survey/components/SurveyPackageEdit");

// DataBase
const ProductDatabase = () => import("@/views/database/ProductDatabase");

// Disclaimer
const Disclaimer = () => import("@/views/database/Disclaimer");

// Questionnaire
const QuestionnairePage = () => import("@/views/questionnaire");
const Questionnaire = () => import("@/views/questionnaire/Questionnaire");
const Prescription = () => import("@/views/questionnaire/Prescription");

// Recommendation
const RecommendationPage = () => import("@/views/recommendation");
const Recommendation = () => import("@/views/recommendation/Recommendation");
const Profile = () => import("@/views/recommendation/Profile");

// Countermat
const CountermatPage = () => import("@/views/countermat");
const Countermat = () => import("@/views/countermat/Countermat");
const LensSlider = () => import("@/views/countermat/LensSlider");

// Countermat
const Other = () => import("@/views/other");
const ButtonText = () => import("@/views/other/ButtonText");

// Email
const Email = () => import("@/views/email");
const FindAnOpticianEmail = () => import("@/views/email/FindAnOpticianEmail");
const AfterSalesEmail = () => import("@/views/email/AfterSalesEmail");

// Login
const Login = () => import("@/views/pages/Login");
const Logout = () => import("@/views/pages/Logout");
const Redirect = () => import("@/views/pages/Redirect");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const LoginFake = () => import("@/views/pages/LoginFake");
Vue.use(Router);

export const router = new Router({
  base: "/", // process.env.VUE_APP_CMS_URL,
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

router.beforeEach((to, from, next) => {
  const publicPages = ["/login", "/logout", "/redirect", "/forgot-password", "/reset-password"];
  const path = to.path;
  const isPublicPages = publicPages.some(x => path.indexOf(x) >= 0);
  const user = LocalStoreService.getUser();
  if (notEditModePages.includes(path)) {
    store.commit("survey/remove");
  } else {
    store.commit("survey/save", LocalStoreService.getSurvey());
  }

  const adminSettingPages = ["/admin"];
  const isAdminPage = adminSettingPages.some((x) => path.includes(x));
  const adminRoutes = MENU_LIST.find((x) => x.id == "admin").childs;
  const survey = store.state.survey;
  const isSelectedSurvey = survey ? survey.id > -1 : false;
  const userRole = store.state.auth.user && store.state.auth.user.role;
  const canAccessLenSelector = store.getters["auth/canAccessLenSelector"];

  if (isPublicPages) {
    next();
  } else if (!user) {
    next("/login");
  } else {
    if (isAdminPage) {
      const activeRoute = adminRoutes.find((x) => path.includes(x.link));
      if (
        (activeRoute && activeRoute.roles.includes(userRole)) ||
        path == "/admin"
      ) {
        next();
      } else {
        // find page by role
        const defaultPage = adminRoutes.find(x => x.roles.includes(userRole))
        if(defaultPage) {
          next(defaultPage.link);
        }
        else {
          next("/login");
        }
      }
    } else if (canAccessLenSelector && isSelectedSurvey) {
      next();
    } else {
      const redrectSurveyPath = canAccessLenSelector ? "/survey" : `${DOCUMENT_PATH}/files`
      if (path == redrectSurveyPath) {
        next();
      } else {
        window.location.href = redrectSurveyPath;
      }
    }
  }
});

function configRoutes() {
  return [
    {
      path: "/",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "survey",
          name: "Survey",
          component: Survey,
          children: [
            {
              path: "",
              name: "SurveyCreation",
              component: SurveyCreation,
            },
            {
              path: "file",
              name: "SurveyFile",
              component: SurveyFile,
            },
            {
              path: "survey-package",
              name: "SurveyPackage",
              component: SurveyPackage,
              children: [
                {
                  path: "detail",
                  name: "SurveyPackageDetail",
                  component: SurveyPackageDetail,
                },
                {
                  path: "add",
                  name: "SurveyPackageAdd",
                  component: SurveyPackageAdd,
                },
                {
                  path: "edit",
                  name: "SurveyPackageEdit",
                  component: SurveyPackageEdit,
                },
              ],
            },
          ],
        },
        {
          path: "admin",
          name: "Admin",
          component: Admin,
          children: [
            {
              path: '/',
              redirect: 'platform'
            },
            {
              path: "platform",
              name: "Manage Platform",
              component: ManagePlatform,
            },
            {
              path: "users",
              name: "Manage Users",
              component: ManageUser,
            },
            {
              path: "privacy",
              name: "Privacy Statement",
              component: PrivacySetting,
            },
          ],
        },
        {
          path: "database/product-database",
          name: "ProductDatabase",
          component: ProductDatabase,
        },
        {
          path: "database/disclaimer",
          name: "Disclaimer",
          component: Disclaimer,
        },
        {
          path: "questionnaire",
          name: "Questionnaire",
          component: QuestionnairePage,
          children: [
            {
              path: "",
              name: "Questionnaire",
              component: Questionnaire,
            },
            {
              path: "prescription",
              name: "Prescription",
              component: Prescription,
            },
          ],
        },
        {
          path: "recommendation",
          name: "Recommendation",
          component: RecommendationPage,
          children: [
            {
              path: "",
              name: "Profile",
              component: Profile,
            },
            {
              path: "rcmd",
              name: "Recommendation",
              component: Recommendation,
            },
          ],
        },
        {
          path: "countermat",
          name: "Countermat",
          component: CountermatPage,
          children: [
            {
              path: "",
              name: "Countermat",
              component: Countermat,
            },
            {
              path: "lens-slider",
              name: "LensSlider",
              component: LensSlider,
            },
          ],
        },
        {
          path: "other",
          name: "Other",
          component: Other,
          children: [
            {
              path: "buttons-texts",
              name: "ButtonText",
              component: ButtonText,
            },
          ],
        },
        {
          path: "email",
          name: "Email",
          component: Email,
          children: [
            {
              path: "optician-email",
              name: "FindAnOpticianEmail",
              component: FindAnOpticianEmail,
            },
            {
              path: "after-sales-email",
              name: "AfterSalesEmail",
              component: AfterSalesEmail,
            },
          ],
        },
      ],
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/login-fake",
      name: "Login Fake",
      component: LoginFake,
    },
    {
      path: "/logout",
      name: "Logout",
      component: Logout,
    },
    {
      path: "/redirect",
      name: "Redirect",
      component: Redirect,
    },
    {
      path: "/forgot-password",
      name: "Forgot password",
      component: ForgotPassword,
    }
  ];
}
