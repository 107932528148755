import { http } from '../plugin/http2-common';

const BASE_URL = '/api/survey'

class SurveyService {

  getAll() {
    return http.get(`${BASE_URL}`);
  }

  getByCountryCode(countryCode) {
    return http.get(`${BASE_URL}/country/${countryCode}`);
  }

  getByCountryCodeForPackage(countryCode) {
    return http.get(`${BASE_URL}/country/${countryCode}/package`);
  }

  getByDesc(searchText) {
    return http.get(`${BASE_URL}/search`, {
      params: {
        searchText
      }
    });
  }

  create(data) {
    return http.post(`${BASE_URL}`, data);
  }

  duplicate(id) {
    return http.post(`${BASE_URL}/${id}`);
  }

  publish(id) {
    return http.post(`${BASE_URL}/publish/${id}`);
  }

  publishFlag(id, publishedFlag) {
    return http.post(`${BASE_URL}/publishFlag/${id}`, { publishedFlag });
  }

  update(data) {
    return http.put(`${BASE_URL}/${data.id}`, data);
  }

  remove(id) {
    return http.delete(`${BASE_URL}/${id}`);
  }

  edit(id) {
    return http.put(`${BASE_URL}/edit/${id}`);
  }

  download(id) {
      return http.get(`${BASE_URL}/download/${id}`, { responseType: 'blob' });
  }

  downloadHistory(id) {
      return http.get(`${BASE_URL}/downloadHistory/${id}`, { responseType: 'blob' });
  }
  
}

export default new SurveyService();