
const USER_KEY = "user";
const SURVEY_KEY = "survey";
const SECRET_KEY = "vuNMB478*Tt7W*cnQM3c65@Mzh6hT$=TuE^wHQN!";

const CryptoJS = require("crypto-js")

class LocalStoreService {

    static $storage = localStorage;
    static $sessionStorage = sessionStorage;
    
    static getSurvey() {
        const survey = this.$sessionStorage.getItem(SURVEY_KEY);
        return survey ? JSON.parse(survey) : {
            id : -1,
            country : {code: '', name: ''},
            language : {code: '', name: ''},
            countryAndLanguage : ''
        };
    }

    static setSurvey(survey) {
        this.$sessionStorage.setItem(SURVEY_KEY, JSON.stringify(survey));
    }

    static removeSurvey() {
        this.$sessionStorage.removeItem(SURVEY_KEY);
    }

    static getUser() {
        const user = this.$sessionStorage.getItem(USER_KEY) || this.$storage.getItem(USER_KEY);
        if(!user){
            return null;
        }

        const decryptUser = this.decrypt(user)
        return user ? JSON.parse(decryptUser) : null;
    }

    static setUser(user) {
        if(!user){
            return;
        }
        const userJson = JSON.stringify(user)
        const encryptUser = this.encrypt(userJson)
        this.$sessionStorage.setItem(USER_KEY, encryptUser);
        this.$storage.setItem(USER_KEY, encryptUser);
    }

    static removeUser() {
        this.$sessionStorage.removeItem(USER_KEY);
        this.$storage.removeItem(USER_KEY);
    }

    static encrypt (src) {
        return CryptoJS.AES.encrypt(src, SECRET_KEY).toString()
    }

    static decrypt (src) {
        const bytes = CryptoJS.AES.decrypt(src, SECRET_KEY)
        return bytes.toString(CryptoJS.enc.Utf8)
    }

    static setTokenIsExpire() {
        this.$sessionStorage.setItem("isExpire", true);
    }

    static getTokenIsExpire() {
        return this.$sessionStorage.getItem("isExpire");
    }

    static clearTokenIsExpire() {
        if(this.$sessionStorage.getItem("isExpire")) {
            this.$sessionStorage.removeItem("isExpire");
        }
    }

}
  
module.exports = LocalStoreService
  