import CoreuiVue from '@coreui/vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'core-js/stable'
import moment from 'moment'
import * as VeeValidate from 'vee-validate'
import * as rules from "vee-validate/dist/rules"
import Vue from 'vue'
import VueClipboard from 'vue-clipboard2'
import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import TextareaAutosize from 'vue-textarea-autosize'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import App from './App'
import './assets/css/style.css'
import { iconsSet as icons } from './assets/icons/icons.js'
import { router } from './router'
import store from './store/store'

import VueCookies from "vue-cookies";
Vue.use(require("vue-cookies"));
Vue.use(VueCookies);

Vue.config.performance = true
Vue.use(CoreuiVue)
// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
Vue.use(TextareaAutosize)
// Install VeeValidate rules
Object.keys(rules).forEach(rule => {
  VeeValidate.extend(rule, rules[rule]);
});
Vue.use(VeeValidate)
Vue.use(VueLoading);
Vue.use(VueToast, {
  position: 'top-right',
  duration: 3000
});
Vue.use(VueClipboard)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.prototype.$log = console.log.bind(console)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  components: {
    App
  },
  template: '<App/>'
})
