import axios from "axios";
import Vue from "vue";
import { CONTEXT_PATH } from "../constants/Paths";
import { http } from "../plugin/http2-common";
import LocalStoreService from "./LocalStoreService";

const httpNonLogin = axios.create({
  baseURL: CONTEXT_PATH,
});

class AuthService {
  login(user) {
    const otpData = {};
    if (user.otp) {
      otpData.otp = true;
    }
    return http
      .post("api/login", {
        username: user.username,
        password: user.password,
        ...otpData,
      })
      .then((response) => {
        return { ...response.data, username: user.username };
      })
      .catch((err) => {
        throw err;
      });
  }

  changePassword(oldPassword, newPassword) {
    return http
      .post("api/change-password", {
        oldPassword,
        newPassword,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  forgetPassword(email) {
    return http
      .post("api/forget-password", {
        email,
      })
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        throw err;
      });
  }

  getUserInfo(token) {
    return httpNonLogin.get(`/user/info`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
  }

  logout() {
    sessionStorage.clear()
    localStorage.clear()
    Vue.$cookies.remove("token")
    Vue.$cookies.remove("token_azure")
    LocalStoreService.removeUser();
  }

  loginAzure(token) {
    return http
    .post("/azure/login", {
      token: token
    })
    .then((response) => {
      return { ...response.data, username: response.data.username };
    })
    .catch((err) => {
      throw err;
    });
  }
}

export default new AuthService();
