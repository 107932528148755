/* eslint-disable no-console */
import _ from 'lodash'
import { addPropertyData } from '../../common/CommonUtils'

const other = {
    namespaced: true,
    state: () => ({
            isRemoveSurveyStatus: false,
            isStoreEmail:false,
            editStatus: false,
            otherData: [],
            otherOriginData: []
        }),
    mutations: {
        changeIsRemoveSurveyStatus(state, payload = { type: '', data: false}) {
            state.isRemoveSurveyStatus = payload.data
        },
        changeIsStoreEmail(state, payload = { type: '', data: false}) {
            state.isStoreEmail = payload.data
        },
        changeEditStatus(state, payload = { type: '', data: false}) {
            state.editStatus = payload.data
        },
        changeOtherData(state, payload = { data: []}){
            state.otherData = _.cloneDeep(payload.data)
        },
        changeOtherOriginData(state, payload = { data: []}){
            state.otherOriginData = _.cloneDeep(payload.data)
        }
    },
    getters: {

    },
    actions: {  
        initialOtherData(state, payload = {}){
            state.commit('changeOtherData', addPropertyData(_.cloneDeep(payload), { propertyName: 'isEdit', value: false }))
            state.commit('changeOtherOriginData', payload)
        },
    }
}

export default other;