import { ROLE_ADMIN, ROLE_COUNTRY, ROLE_STANDARD } from "./UserRole";

export const MENU_LIST = [
  {
    id: "survey",
    link: "/survey",
    title: "Survey",
    isSurvey: true,
    childs: [
      {
        link: "/database/product-database",
        label: "DATABASE",
      },
      {
        link: "/questionnaire",
        label: "QUESTIONNAIRE",
      },
      {
        link: "/recommendation",
        label: "PERSONA/RECOMMENDATION",
      },
      {
        link: "/countermat",
        label: "COUNTERMAT/LENS SLIDER",
      },
      {
        link: "/other/buttons-texts",
        label: "OTHERS",
      },
      {
        link: "/email",
        label: "EMAIL",
      },
    ],
  },
  {
    id: "admin",
    link: "/admin",
    title: "Admin",
    isSurvey: false,
    childs: [
      {
        link: "/admin/platform",
        label: "MANAGE PLATFORM",
        roles: [ROLE_ADMIN],
      },
      {
        link: "/admin/users",
        label: "MANAGE USERS",
        roles: [ROLE_ADMIN, ROLE_COUNTRY],
      },
      {
        link: "/admin/privacy",
        label: "PRIVACY STATEMENT",
        roles: [ROLE_ADMIN, ROLE_COUNTRY, ROLE_STANDARD],
      },
    ],
  },
];
