export default {
    editable: false,
    message: '',
    showError: false,
    progress: 0,
    singleVisionCategory: [],
    progressiveLensCategory: [],
    voilaCategory: [],
    seriesCategory: [],
    conventionalCategory: {}
}